import { ButtonGroup, Container, Divider, Image, Stack, Text } from '@chakra-ui/react';
import logo from './gemidyne.png';

export const Footer: React.FC = () => (
    <Container as="footer" role="contentinfo" py={{ base: '12', md: '16' }}>
        <Divider mb={8} />

        <Stack spacing={{ base: '4', md: '5' }}>
            <Stack justify="space-between" direction="row" align="center">
                <Image src={logo} />
                <ButtonGroup variant="ghost">
                
                </ButtonGroup>
            </Stack>
            <Text fontSize="sm" color="subtle">
                &copy; {new Date().getFullYear()} Gemidyne Ltd. All rights reserved.
            </Text>
        </Stack>
    </Container>
)