import { Auth0Provider } from '@auth0/auth0-react';
import { theme } from '@chakra-ui/pro-theme';
import { ChakraProvider, extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app/layout/App';
import reportWebVitals from './reportWebVitals';

const dekomoriTheme: ThemeConfig = extendTheme(
  {
    config: {
      useSystemColorMode: false,
      initialColorMode: 'dark',
    },
  },
  theme
);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ChakraProvider resetCSS theme={dekomoriTheme}>
      <QueryClientProvider client={queryClient}>
        {/* <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
          redirectUri={window.location.origin}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
          scope={process.env.REACT_APP_AUTH0_SCOPES}
          useRefreshTokens={true}> */}
        {/* <PayPalScriptProvider
            options={{
              'client-id': 'AbO7ooAOmITTd4ZCy8jt3rrPZsWOia6jOIeFGw0InPt7rwIzJb8xITeuD_EgqBMF9PlqK5_xsXbYteOg',
              currency: 'GBP',
              intent: 'subscription',
              vault: true,
            }}> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </PayPalScriptProvider> */}
        {/* </Auth0Provider> */}
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
