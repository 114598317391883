import { useAuth0 } from '@auth0/auth0-react';
import { Container, Spinner } from '@chakra-ui/react';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { Footer } from './Footer';
import { NavBar } from './NavBar';

const UnauthenticatedPage = lazy(() => import('../../pages/UnauthenticatedPage'));

export const App: React.FC = () => {
  return (
    <Container style={{ marginTop: '7em' }}>
      <Routes>
        <Route path="*" element={<UnauthenticatedPage />} />
      </Routes>
      <Footer />
    </Container>
  );

  // const { isLoading, isAuthenticated } = useAuth0();

  // if (isLoading) {
  //   return (
  //     <Container textAlign='center' pt={10}>
  //       <Spinner size='xl' />
  //     </Container>
  //   )
  // }

  // if (!isAuthenticated) {
  //   return (
  //     <Container style={{ marginTop: '7em' }}>
  //       <Routes>
  //         <Route path='*' element={<UnauthenticatedPage />} />
  //       </Routes>
  //       <Footer />
  //     </Container>
  //   )
  // }

  // return (
  //   <>
  //     <NavBar />
  //     <Container style={{ marginTop: '1em' }}>
  //       <Suspense fallback={
  //         <Container textAlign='center' pt={10}>
  //           <Spinner size='xl' />
  //         </Container>
  //       }>
  //         <Routes>
  //           <Route path="/" element={<Homepage />} />
  //           <Route path="game-servers" element={<GameServerIndexPage />} />
  //           <Route path="game-servers/:id" element={<GameServerDetailsPage />} />
  //           <Route path='*' element={<NotFoundPage />} />
  //         </Routes>
  //       </Suspense>
  //     </Container>

  //     <Footer />
  //   </>
  // )
};
